.modal{
    display: flex;
    gap: 15px;
}

.modalItem{
    display: flex;
    justify-content: space-between;
}

.modalLink{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 130px;
    width: 100%;
    padding: 0 10px;
}

.modalImg{
    width: 100%;

}

.modalBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
}

.modalText{
    padding: 0 10px;
}

.modalBtn{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
}